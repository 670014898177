import axios from "axios";

import { ActionContext } from "vuex";
import {instance} from "@/util/Alerts";
type InductorContext = ActionContext<InductorState, RootState>;

const baseUrl = "https://api.orbweaver.com/kemet-max/api/v1/max/calcl/series/";

const state = (): InductorState => ({
    seriesSelect: "",
    seriesOptions: [] as BaseDropdownOption[],
    sizeSelect: "",
    sizeOptions: [] as BaseDropdownOption[],
    inductors: [] as InductorModel[] 
});

const getters = {
};

const mutations = {
    setSeriesSelect(state: InductorState, payload: string) {
        state.seriesSelect = payload;
    },
    setSizeSelect(state: InductorState, payload: string) {
        state.sizeSelect = payload;
    },
    setSeriesOptions(state: InductorState, payload: BaseDropdownOption[]) {
        state.seriesOptions = payload;
    },
    setSizeOptions(state: InductorState, payload: BaseDropdownOption[]) {
        state.sizeOptions = payload;
    },
    setInductors(state: InductorState, payload: InductorModel[]) {
        state.inductors = payload;
    },
};

const actions = {
    setSeriesSelect(context: InductorContext, payload: string) {
        context.commit("setSeriesSelect", payload);
    },
    setSizeSelect(context: InductorContext, payload: string) {
        context.commit("setSizeSelect", payload);
    },
    getSeriesOptions(context: InductorContext) {
        const opts = [] as BaseDropdownOption[];
        instance.get(baseUrl).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                opts.push({
                    value: response.data[i],
                    label: response.data[i],
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setSeriesOptions", opts);
        });
    },
    getSizeOptions(context: InductorContext) {
        const opts = [] as BaseDropdownOption[];
        const url = baseUrl
            + context.state.seriesSelect + "/case_size/";
        instance.get(url).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                opts.push({
                    value: response.data[i],
                    label: response.data[i],
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setSizeOptions", opts);
        });
    },
    getInductors(context: InductorContext) {
        const temp = [] as InductorModel[];
        const url = baseUrl
            + context.state.seriesSelect + "/case_size/"
            + context.state.sizeSelect;
        instance.get(url).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                // TODO: is this the correct place to setup these params? we could add a similar CapacitorWithProp type and only instantiate these parameters in the mutation for when we add a part
                const frequency: InputNumber = {
                    title: "Freq. (kHz)",
                    select: 100,
                    min: 1,
                    max: 1000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 7,
                };
                const voltageInput: InputNumber = {
                    title: "Vin (V)",
                    select: 12.0,
                    min: 0.1,
                    max: 1000.0,
                    step: 0.1,
                    disabled: false,
                    hidden: false,
                    width: 6,
                };
                const voltageOutput: InputNumber = {
                    title: "Vout (V)",
                    select: 5.0,
                    min: 0.1,
                    max: 1000.0,
                    step: 0.1,
                    disabled: false,
                    hidden: false,
                    width: 6,
                };
                const currentOutput: InputNumber = {
                    title: "I (A)",
                    select: 0.5,
                    min: 0.01,
                    max: 100.0,
                    step: 0.01,
                    disabled: false,
                    hidden: false,
                    width: 6,
                };
                const temperatureAmbient: InputNumber = {
                    title: "Ambient (°C)",
                    select: 25,
                    min: -55,  // TODO: need to set from part info
                    max: 155,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 6,
                };
                const param: CalcLParameters = {
                    frequency,
                    voltageInput,
                    voltageOutput,
                    currentOutput,
                    temperatureAmbient
                };
                temp.push({
                    pn: response.data[i]["part_number"],
                    value: response.data[i]["nominal_inductance"],
                    current: response.data[i]["rated_current"],
                    size: response.data[i]["case_size"],
                    instances: 1,
                    param: param
                } as InductorModel);
            }
            // sort by value. if equal value, sort by pn
            temp.sort((a, b) => (a.value > b.value) ? 1 : (a.value === b.value) ? ((a.pn > b.pn) ? 1 : -1) : -1 );
            context.commit("setInductors", temp);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
