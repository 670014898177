<template>
    <div id="simulation-container">
        <div class="cap-title">Capacitor Simulation</div>

        <div class="e-panel:3 l-box:inset2 cap-plot_outer">
            <!-- Inputs shared between all plots -->
            <SharedParameters :key="'sp' + componentKey" data-html2canvas-ignore/>

            <!-- Plot -->
            <div id="chart-to-download">
                <div v-if="!validResults" :style="chartStyle" data-html2canvas-ignore>
                    <base-loading-indicator v-if="pendingRequests"></base-loading-indicator>
                    <base-chart-error-message v-if="!validResults"/>
                </div>
                <div v-if="validResults">
                    <div id="calcz-chart-container"
                         v-on:click="chartClickHandler"
                         :style="chartStyle">
                        <BaseChart id="basePlot"
                                   :data="plot.data" :options="plot.options"
                                   :style="chartStyle"/>
                    </div>
                </div>

                <!-- Parts Table -->
                <CapacitorTable :key="'ct' + componentKey" id="cap-table-image"/>

                <!-- File Exports -->
                <Export data-html2canvas-ignore/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

// External
import {AxiosResponse} from "axios";
import debounce from "lodash.debounce";
import Chart from "chart.js";

// State
import {createNamespacedHelpers} from "vuex";
// Components
import CapacitorTable from "./CapacitorTable.vue";
import SharedParameters from "./SharedParameters.vue";
import Export from "./Export.vue";

// Plot Helpers
import {chartMixin} from "@/mixins/chartMixin";
/* util/ChartJsHelpers */
import {
    calculateSciNotationExponent,
    createDatasets,
    exponentPrefixes,
    normalizedToExponent,
    plotTick,
    plotTooltip,
    unitConversion,
} from "@/util/ChartJsHelpers";
import {multFreqBackground} from "@/util/Chart/MultFreqBackground";
import pointStyles from "@/util/PointStyles";
// Local
import {plotUrl} from "@/util/Urls";
import Vue from "vue";
import {instance} from "@/util/Alerts";

const {mapState, mapGetters, mapActions} = createNamespacedHelpers("calcZ");

Chart.Tooltip.positioners.fixedLeft = function (elements, position) {
    return {
        x: 85,
        y: position.y >= 0 ? position.y : 0,
        //y: 75,
    };
};
Chart.Tooltip.positioners.fixedTopRight = function (elements, position) {
    const chart = elements[0]._chart;
    const area = chart.chartArea;
    const left = area.right;
    const pad = -10;
    let top = area.top;
    return {
        x: left + pad,
        y: top,
    };
};
Chart.Tooltip.positioners.fixedTopLeft = function (elements, position) {
    const chart = elements[0]._chart;
    const area = chart.chartArea;
    const left = area.left;
    const pad = 10;
    let top = area.top;
    return {
        x: left + pad,
        y: top,
    };
};
Chart.Tooltip.positioners.fixedBelowXAxis = function (elements, position) {
    const bottom = elements[0]._xScale.bottom;
    return {
        x: position.x,
        y: bottom,
    };
};
Chart.Tooltip.positioners.fixedAboveGraph = function (elements, position) {
    return {
        x: position.x,
        y: 0,
    };
};
Chart.Tooltip.positioners.cursor = function (elements, position) {
    return {
        x: position.x,
        y: position.y >= 0 ? position.y : 0,
    };
};

const NumPartsListIsEmpty = 1; // 1 bcuz Combined @ idx=0 does not count

export default Vue.extend({
    mixins: [chartMixin], // resetPlot(), initializePlot()
    data() {
        return {
            debouncedFetchPlotData: debounce(
                (this as any).fetchPlotData,
                500
            ) as Function, // prevent duplicate requests
            secondYAxis: {} as Chart.ChartYAxe, // TODO set yaxes list, don't push and pop...
            validResults: true,
            pendingRequests: 0,
            chartMouseHold: false,
            tooltipTitle: "",
            tooltipContent: []
        };
    },
    components: {
        SharedParameters,
        CapacitorTable,
        Export,
    },
    mounted() {
        const $this = this;
        window.addEventListener('keyup', function (event) {
            if (event.keyCode === 68 && $this.tooltipContent.length > 0) {
                instance.post("/api/tooltip-csv", { "tooltipTitle": $this.tooltipTitle, "tooltipContent": $this.tooltipContent }, { responseType: "blob" }).then((response) => {
                    let blob = new Blob([response.data], { type: response.data.type });
                    const contentDisposition = response.headers['content-disposition'];
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    let fileName = 'tooltip-data.txt';
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename=(.+)/)!;
                        if (fileNameMatch.length === 2)
                            fileName = fileNameMatch[1];
                    }
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            }
        });
        // url parameter "pn"
        const partNumber = this.$route.query.pn;
        if (partNumber) {
            // Add parts & restore plot
            const distributor = this.$route.query.disty;
            const method = distributor ? "disty-" + distributor : "URL";
            this.addCapacitorByPartNumber({
                partNumber,
                method,
            });
            this.debouncedFetchPlotData();

            // Close sidebar if link from distributor
            if (distributor) {
                var sidebar = document.getElementsByClassName(
                    "sidebar_toggle"
                )[0] as HTMLElement;
                sidebar.click();
            }
        }
    },
    computed: {
        // Connect vuex to component
        ...mapState([
            "componentKey",
            "highlight",
            "parts",
            "partStyleApplied",
            "partStyleAvailable",
            "plotType",
            "shared",
            "values",
        ]),
        ...mapGetters([
            "body",
            "pnStrings",
            "individualParameters",
            "toleranceList",
        ]),
        allFilmParts() {
            let partsArrayCopy = [...this.parts];
            partsArrayCopy.shift();
            let allFilmParts = partsArrayCopy.every((p) => p.capType === "Film") && partsArrayCopy.length > 0;
            return allFilmParts
        }
    },
    methods: {
        ...mapActions([
            "setHighlight",
            "incrementComponentKey",
            "addCapacitorByPartNumber"
        ]),
        chartClickHandler(event): void {
            if (event.type === "click") {
                const chartElem = document.getElementById("base-chart-id")!;
                this.chartClickHold = !this.chartClickHold;
                if (this.chartClickHold) {
                    chartElem.style.pointerEvents = "none";
                } else {
                    chartElem.style.pointerEvents = "auto";
                }
            }
        },
        // Plot update
        fetchPlotData(): void {
            // When no parts, show KSIIIM logo
            if (this.parts.length === NumPartsListIsEmpty) {
                //this.validResults = true;
                this.resetPlot(); //mixin
                return;
            }

            // Properties common to all plots regardless of response
            this.initializePlot(); //mixin

            // Add session guid for plot request
            const req = this.body;
            // Set different initial freq. range when all film parts are selected
            if (this.allFilmParts) {
                req.start = this.shared.dropdowns.minFreq.filmSelect;
                req.stop = this.shared.dropdowns.maxFreq.filmSelect
            }
            if (this.body.plotType === "MultFreq") req.start = 100;
            req["session"] = window.localStorage.getItem("uuid");

            // Begin request, update plot with response
            this.pendingRequests++;

            let $this = this; 
            const plotType = this.plotType.select;
            instance
                .post(plotUrl, req)
                .then((response: AxiosResponse<PlotResponseModel>) => {
                    this.validResults = response.data.seriesList.length !== 0;
                    if (!this.validResults) return;

                    // Reset datasets inside response so plot is not empty while waiting
                    this.plotData = {} as Object;
                    this.plotData.datasets = [] as any[];

                    // All plots
                    if (this.plotOptions.scales.yAxes.length == 2)
                        this.plotOptions.scales.yAxes.pop();
                    this.plotOptions.title.text = response.data.title;
                    this.plotOptions.scales.xAxes[0].scaleLabel.labelString =
                        response.data.xLabel;
                    this.plotOptions.scales.yAxes[0].scaleLabel.labelString =
                        response.data.seriesList[0].yLabel;

                    $this.tooltipTitle = "";
                    $this.tooltipContent = [] as string[];
                    let test1 = [] as string[];
                    this.plotOptions.tooltips = {
                        intersect: false, // show tooltip without direct intersection of point with mouse
                        mode: "interpolate", // custom mode for crosshair plugin
                        position: "fixedTopRight",
                        caretSize: 0,
                        callbacks: {
                            title(
                                tooltipItem: Array<Chart.ChartTooltipItem>,
                                data: Chart.ChartData
                            ) {
                                const tooltipTitle = plotTooltip(
                                    response.data.xLabel,
                                    tooltipItem[0].xLabel! as number,
                                    response.data.xUnit,
                                    2);
                                $this.tooltipTitle = tooltipTitle;
                                return tooltipTitle;
                            },
                            label(
                                tooltipItem: Chart.ChartTooltipItem,
                                data: Chart.ChartData
                            ) {
                                const tooltipContent = plotTooltip(
                                    data.datasets![tooltipItem.datasetIndex!].label as string,
                                    tooltipItem.yLabel! as number,
                                    response.data.seriesList[0].yUnit,
                                    2
                                );
                                if ($this.tooltipContent.length === data.datasets!.length) {
                                    $this.tooltipContent = [];
                                    $this.tooltipContent.push(tooltipContent)
                                }
                                else {
                                    $this.tooltipContent.push(tooltipContent);
                                }

                                return tooltipContent;
                            },
                        },
                    };

                    // Helper variables
                    const combined = response.data.request.combine === "Yes";
                    const calculated = response.data.request.calculated === "Yes";
                    let twoYAxes = false;

                    // Individual plots
                    if (plotType === "Imp,ESR") {
                        // Datasets
                        twoYAxes = false;
                        this.plotData.datasets = createDatasets(
                            response.data.seriesList,
                            this.partStyleApplied,
                            this.highlight,
                            combined,
                            calculated,
                            twoYAxes
                        );

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: number[]) => {
                                const unit = response.data.xUnit;
                                return plotTick(value, index, arr, 0, [1], unit);
                            },
                        };

                        // Y-Axis
                        this.plotOptions.scales.yAxes[0].type = "logarithmic";
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: number[]) => {
                                const unit = response.data.seriesList[0].yUnit;
                                return plotTick(value, index, arr, 0, [1], unit);
                            },
                        };
                    } else if (plotType === "I,V" || plotType === "Cap,Ind") {
                        // Datasets
                        twoYAxes = true;
                        this.plotData.datasets = createDatasets(
                            response.data.seriesList,
                            this.partStyleApplied,
                            this.highlight,
                            combined,
                            calculated,
                            twoYAxes
                        );

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: number[]) => {
                                return plotTick(value, index, arr, 0, [1], response.data.xUnit);
                            },
                        };

                        // Y-Axis
                        this.plotOptions.scales.yAxes[0].type = "logarithmic";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: number[]) => {
                                const unit = response.data.seriesList[0].yUnit;
                                return plotTick(value, index, arr, 0, [1], unit);
                            },
                        };
                        this.secondYAxis = {
                            display: true,
                            id: response.data.seriesList[1].yLabel,
                            type: "logarithmic",
                            position: "right",
                            scaleLabel: {
                                display: true,
                                labelString: response.data.seriesList[1].yLabel,
                                fontColor: "black",
                                fontFamily: "sans-serif",
                                fontSize: 16,
                            },
                            gridLines: {
                                display: true,
                                color: "rgba(0,73,144,0.2)", // 'light-grey' is default
                                //borderDash: [2, 5], //dotted
                                z: 0,
                            },
                            ticks: {
                                autoSkip: false,
                                minRotation: 0,
                                maxRotation: 0,
                                callback: (value: number, index: number, arr: number[]) => {
                                    const unit = response.data.seriesList[1].yUnit;
                                    return plotTick(value, index, arr, 0, [1], unit);
                                },
                            },
                        };
                        this.plotOptions.scales.yAxes.push(this.secondYAxis);

                        // Tooltip
                        this.plotOptions.tooltips = {
                            intersect: false, // show tooltip without direct intersection of point with mouse
                            mode: "interpolate", // custom mode for crosshair plugin
                            position: "fixedTopRight",
                            caretSize: 0,
                            callbacks: {
                                title(
                                    tooltipItem: Array<Chart.ChartTooltipItem>,
                                    data: Chart.ChartData
                                ) {
                                    return plotTooltip(
                                        response.data.xLabel,
                                        tooltipItem[0].xLabel! as number,
                                        response.data.xUnit,
                                        2
                                    );
                                },
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    // From chart
                                    const label = data.datasets![tooltipItem.datasetIndex!]
                                        .label as string;
                                    const fullUnit = label.split(" ")[
                                    label.split(" ").length - 1
                                        ];

                                    // Unit
                                    const firstUnit = response.data.seriesList[0].yUnit;
                                    const secondUnit = response.data.seriesList[1].yUnit;
                                    const unit = fullUnit === "A(rms)" ? firstUnit : secondUnit;

                                    // Generate string for tooltip
                                    return plotTooltip(
                                        label,
                                        tooltipItem.yLabel! as number,
                                        unit,
                                        2
                                    );
                                },
                            },
                        };
                    } else if (plotType === "S11" || plotType === "S21") {
                        // Datasets
                        for (let i = 0; i < response.data.seriesList.length; i++) {
                            if (response.data.request.combine === "No" && i < 2) {
                                continue;
                            }

                            // In the values array, there will be two datasets for each part.
                            // Based on i, determine indexes for:
                            // - part number
                            // - legend
                            let partListIndex = Math.floor(i / 2);
                            let partStyleIndex = this.partStyleApplied[partListIndex];

                            let legendIndex = 0;
                            if (i % 2 === 0) {
                                /* even */
                                legendIndex = 0; // e.g. Impedance
                            } else {
                                /* odd */
                                legendIndex = 1; // e.g. ESR
                            }

                            // Legend
                            let pnString = response.data.seriesList[i].kemetPn;
                            let legendString = response.data.seriesList[i].yLabel;

                            // Highlight
                            let highlightWidth = 1;
                            if (this.highlight === partListIndex) {
                                highlightWidth = 10;
                            }

                            // Order
                            let orderIndex = partListIndex + 1;
                            if (this.highlight === partListIndex) {
                                orderIndex = 0;
                            }

                            // Each `chart.js` datasets contains:
                            let dataset = {
                                yAxisID: legendString,
                                pointStyle: pointStyles[partStyleIndex].point,
                                pointRadius: pointStyles[partStyleIndex].radius,
                                pointBorderWidth: pointStyles[partStyleIndex].width,
                                backgroundColor: pointStyles[partStyleIndex].color[legendIndex],
                                borderColor: pointStyles[partStyleIndex].color[legendIndex],
                                rotation: pointStyles[partStyleIndex].rotation,
                                borderWidth: highlightWidth,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString + " - " + legendString,
                                showLine: true,
                                order: orderIndex,
                            };
                            this.plotData.datasets.push(dataset);
                        }

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].gridLines = {
                            display: true,
                            //color: 'rgba(0,0,0,1)', // 'light-grey' is default
                            //borderDash: [2, 5], //dotted
                        };
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                const unit = response.data.xUnit;
                                return plotTick(value, index, arr, 0, [1], unit);
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axes
                        this.plotOptions.scales.yAxes[0].type = "linear";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].gridLines = {
                            display: true,
                            color: "light-grey",
                        };
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: true,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                return value + " db";
                            },
                        };
                        this.secondYAxis = {
                            display: true,
                            id: response.data.seriesList[1].yLabel, //needs to match id of a dataset
                            type: "linear",
                            position: "right",
                            scaleLabel: {
                                display: true,
                                labelString: response.data.seriesList[1].yLabel,
                                fontColor: "black",
                                fontFamily: "sans-serif",
                                fontSize: 16,
                            },
                            gridLines: {
                                display: true,
                                color: "rgba(0,73,144,0.3)", // 'light-grey' is default
                                z: 0,
                            },
                            ticks: {
                                autoSkip: true,
                                callback: (
                                    value: number,
                                    index: number,
                                    arr: Array<number>
                                ) => {
                                    return value + "°";
                                },
                            },
                        };
                        this.plotOptions.scales.yAxes.push(this.secondYAxis);

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false,
                            mode: "interpolate",
                            position: "fixedTopRight",
                            caretSize: 0,
                            callbacks: {
                                title(tooltipItem: Array<Chart.ChartTooltipItem>) {
                                    let title = "Frequency: ";

                                    let value = tooltipItem[0].xLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value.toFixed(2);

                                    title += value + " " + unitExponentsString[idx] + "Hz";
                                    return title;
                                },
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";
                                    let unit = "";
                                    if (
                                        label.split(" ")[label.split(" ").length - 1] ===
                                        response.data.seriesList[0].yLabel
                                    ) {
                                        unit = response.data.seriesList[0].yUnit;
                                    } else {
                                        unit = response.data.seriesList[1].yUnit;
                                    }

                                    let value = tooltipItem.yLabel!;
                                    value = +value;
                                    label += ": " + value.toFixed(1);
                                    label += " " + unit;
                                    return label;
                                },
                            },
                        };
                    } else if (plotType === "Vbias") {
                        // Datasets
                        for (let i = 0; i < response.data.seriesList.length; i++) {
                            // Set flag for valid responses
                            let foundPt = false;
                            let partListIndex = 0;
                            findPart: for (let j = 0; j < this.parts.length; j++) {
                                if (
                                    response.data.seriesList[i].kemetPn === this.parts[j].kemetPn
                                ) {
                                    partListIndex = j;
                                    foundPt = true;
                                    break findPart;
                                }
                            }

                            if (
                                !foundPt &&
                                response.data.seriesList[i].kemetPn === "Combined"
                            ) {
                                continue;
                            }
                            // combined data style is at *reserved* index 0. we don't use combined in this plot
                            let partStyleIndex = this.partStyleApplied[partListIndex];

                            let legendIndex = 0;
                            if (i % 2 === 0) {
                                /* even */
                                legendIndex = 0; // e.g. Impedance
                            } else {
                                /* odd */
                                legendIndex = 1; // e.g. ESR
                            }

                            // Legend
                            let pnString = response.data.seriesList[i].kemetPn;
                            let legendString = "";

                            // Highlight
                            let highlightWidth = 1;
                            if (this.highlight === partListIndex) {
                                highlightWidth = 10;
                            }

                            // Order
                            let orderIndex = partListIndex + 1;
                            if (this.highlight === partListIndex) {
                                orderIndex = 0;
                            }

                            let dataset = {
                                yAxisID: legendString,
                                pointStyle: pointStyles[partStyleIndex].point,
                                pointRadius: pointStyles[partStyleIndex].radius,
                                pointBorderWidth: pointStyles[partStyleIndex].width,
                                backgroundColor: pointStyles[partStyleIndex].color[legendIndex],
                                borderColor: pointStyles[partStyleIndex].color[legendIndex],
                                rotation: pointStyles[partStyleIndex].rotation,
                                borderWidth: highlightWidth,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString,
                                showLine: true,
                                order: orderIndex,
                            };
                            this.plotData.datasets.push(dataset);
                        }

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "linear";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: true,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                return value + " " + response.data.xUnit;
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axes
                        this.plotOptions.scales.yAxes[0].type = "linear";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                const sign = value < 0 ? -1 : 1;
                                value = Math.abs(value);
                                value = value * sign;

                                const decimal = value - Math.floor(value);
                                if (decimal !== 0) {
                                    value = +value.toFixed(2);
                                }
                                return value + response.data.seriesList[0].yUnit;
                            },
                        };

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false,
                            mode: "interpolate",
                            position: "fixedTopRight",
                            caretSize: 0,
                            callbacks: {
                                title(tooltipItem: Array<Chart.ChartTooltipItem>) {
                                    let title = "Voltage: ";

                                    let value = tooltipItem[0].xLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value.toFixed(2);

                                    title += value + " " + unitExponentsString[idx] + "VDC";
                                    return title;
                                },
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    if (
                                        // @ts-ignore
                                        isNaN(data.datasets![tooltipItem.datasetIndex!].interpolatedValue)
                                    ) {
                                        // don't show NaNs in tooltip
                                        return "";
                                    }

                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";
                                    let part: CapacitorModel = {} as CapacitorModel;
                                    let parts = response.data.request.parts;
                                    for (let i = 0; i < parts.length; i++) {
                                        if (label === parts[i].kemetPn) {
                                            part = parts[i];
                                        }
                                    }

                                    let value = tooltipItem.yLabel!;
                                    value = +value;

                                    let adjustedCapacitance = part.capValue * (1 + value / 100); // value is already negative
                                    adjustedCapacitance /= 1000000000000.0; // value is in pF, bring back to normal
                                    const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6, 9];
                                    const unitExponentsString = [
                                        "p",
                                        "n",
                                        "u",
                                        "m",
                                        "",
                                        "k",
                                        "M",
                                        "G",
                                    ];

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (
                                            adjustedCapacitance >=
                                            Math.pow(10, unitExponentsNumber[i])
                                        ) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    adjustedCapacitance =
                                        adjustedCapacitance /
                                        Math.pow(10, unitExponentsNumber[idx]);

                                    //When zoomed, only show 2 decimal places for axis ticks at
                                    //left and right edge of graph

                                    label +=
                                        ": " +
                                        value.toFixed(1) +
                                        response.data.seriesList[0].yUnit +
                                        ", " +
                                        adjustedCapacitance.toFixed(1) +
                                        " " +
                                        unitExponentsString[idx] +
                                        "F";

                                    return label;
                                },
                            },
                        };
                    } else if (plotType === "MultFreq") {
                        // Background colors for temperature thresholds
                        this.plotData.datasets.push(multFreqBackground.green);
                        this.plotData.datasets.push(multFreqBackground.yellow);
                        this.plotData.datasets.push(multFreqBackground.red);
                        // Remove background color datasets from legend
                        this.plotOptions.legend.labels.filter = function (item, chart) {
                            return typeof item.text !== "undefined";
                        };

                        // Title
                        let multFreqTitle = response.data.title + " - ";
                        let multFreqPart: CapacitorModel = this.parts[this.highlight];
                        multFreqTitle += multFreqPart.kemetPn;
                        multFreqTitle +=
                            " @ " + multFreqPart.param.tempAmbient.select + "°C";
                        multFreqTitle += ", " + multFreqPart.param.bias.select + "V";
                        let rtheta = +response.data.note;
                        multFreqTitle += ", " + "Rθ = " + rtheta.toFixed(1) + " °C/W";
                        this.plotOptions.title.text = multFreqTitle;

                        // Datasets
                        for (let i = 0; i < response.data.seriesList.length; i++) {
                            // Legend
                            let pnString = response.data.request.freqList[i].toString();
                            let legendString = response.data.seriesList[i].yLabel;

                            let dataset = {
                                yAxisID: legendString,
                                pointStyle: pointStyles[i].point,
                                pointRadius: pointStyles[i].radius,
                                pointBorderWidth: pointStyles[i].width,
                                backgroundColor: pointStyles[i].color[0],
                                borderColor: pointStyles[i].color[0],
                                rotation: pointStyles[i].rotation,
                                borderWidth: 1,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString + " Hz",
                                showLine: true,
                            } as Chart.ChartDataSets;
                            this.plotData.datasets.push(dataset);
                        }

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].gridLines = {
                            display: true,
                            //color: 'rgba(0,0,0,1)', // 'light-grey' is default
                            //borderDash: [2, 5], //dotted
                        };
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                const unit = response.data.xUnit;
                                return plotTick(value, index, arr, 0, [1, 2, 5], unit);
                            },
                        };

                        // Y-Axis
                        this.plotOptions.scales.yAxes[0].type = "linear";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].gridLines = {
                            display: true,
                            color: "light-grey", // 'light-grey' is default
                            //borderDash: [2, 5], //dotted
                            //z: 1
                        };
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                const decimal = value;
                                if (decimal !== 0) value = +value.toFixed(2);
                                let tick = value.toString() + " ";
                                tick += response.data.seriesList[0].yUnit;
                                return tick;
                            },
                        };

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false, // show tooltip without direct intersection of point with mouse
                            mode: "interpolate", // custom mode for crosshair plugin
                            position: "fixedTopRight",
                            caretSize: 0,
                            callbacks: {
                                title(
                                    tooltipItem: Array<Chart.ChartTooltipItem>,
                                    data: Chart.ChartData
                                ) {
                                    return plotTooltip(
                                        response.data.xLabel,
                                        tooltipItem[0].xLabel! as number,
                                        response.data.xUnit,
                                        1
                                    );
                                },
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    // Freq value as number
                                    let freqStr =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";
                                    freqStr = freqStr.split(" ")[0];
                                    const freqVal = +freqStr;

                                    // Freq value with prefix
                                    const exponent = calculateSciNotationExponent(freqVal);
                                    const prefix = exponentPrefixes[exponent];
                                    const freqNorm = normalizedToExponent(freqVal, exponent);
                                    const freq = freqNorm + " " + prefix + "Hz";

                                    return plotTooltip(
                                        freq as string,
                                        tooltipItem.yLabel! as number,
                                        response.data.seriesList[1].yUnit,
                                        1
                                    );
                                },
                            },
                        };
                    } else if (plotType === "Spice") {
                        // Custom style for SPICE plot
                        let spiceImageUrl = require("./../../../img/" + response.data.image);
                        this.chartStyle = {
                            height: `60vh`, // sets max height, responds to window size
                            minHeight: "450px", // keeps the graph from getting too small
                            position: "relative", // seems unnecessary?
                            marginBottom: "2rem",
                            backgroundImage: `url('${spiceImageUrl}')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "90% 75%",
                            backgroundPosition: "center",
                        };

                        // Title
                        // TODO: this and other titles should be computed on backend
                        let spicePart = this.parts[this.highlight];
                        let spiceTitle = spicePart.kemetPn;
                        spiceTitle += " @ " + spicePart.param.bias.select + "V";
                        spiceTitle +=
                            ", " +
                            spicePart.param.tempAmbient.select +
                            spicePart.param.tempAmbient.title.slice(-3, -1);
                        this.plotOptions.title.text = spiceTitle;

                        // Datasets
                        for (let i = 0; i < response.data.seriesList.length; i++) {
                            // Legend
                            let pnString = response.data.seriesList[i].refDes;

                            // For spice, we never plot combined, start the styles at + 1
                            const styleIndex = i + 1;

                            // Each `chart.js` dataset contains:
                            let dataset = {
                                pointStyle: pointStyles[styleIndex].point,
                                pointRadius: 0.5,
                                pointBorderWidth: 10,
                                backgroundColor:
                                    pointStyles[styleIndex].color[0].slice(0, -4) + "0.0)",
                                borderColor:
                                    pointStyles[styleIndex].color[0].slice(0, -4) + "0.0)",
                                rotation: pointStyles[styleIndex].rotation,
                                borderWidth: 10,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString,
                                showLine: false,
                            };
                            this.plotData.datasets.push(dataset);
                        }

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            callback: (value: number, index: number, arr: Array<number>) => {
                                const remain =
                                    value / Math.pow(10, Math.floor(Chart.helpers.log10(value)));
                                if (remain === 1 || index === 0 || index === arr.length - 1) {
                                    return unitConversion(value, "Hz");
                                } else {
                                    return "";
                                }
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axis
                        this.plotOptions.scales.yAxes[0].display = false;
                        this.plotOptions.scales.yAxes[0].type = "logarithmic";

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false,
                            mode: "interpolate",
                            position: "fixedTopRight", // SPICE plot custom tooltip position
                            caretSize: 0,
                            callbacks: {
                                title(tooltipItem: Array<Chart.ChartTooltipItem>) {
                                    let title = "Frequency: ";
                                    let value = tooltipItem[0].xLabel!;
                                    return title + unitConversion(+value, "Hz", 1);
                                },
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";

                                    let unit = "?";
                                    switch (label[0]) {
                                        case "C":
                                            unit = "F";
                                            break;
                                        case "L":
                                            unit = "H";
                                            break;
                                        case "R":
                                            unit = "Ω";
                                            break;
                                    }

                                    let value = tooltipItem.yLabel!;
                                    return label + ": " + unitConversion(+value, unit, 1);
                                },
                            },
                        };
                    } else if (plotType === "Aging") {
                        // Datasets
                        for (let i = 0; i < response.data.seriesList.length; i++) {
                            if (response.data.request.combine === "No" && i < 2) {
                                continue;
                            }

                            let partListIndex = 0;
                            let legendIndex = 0;

                            partListIndex = Math.floor(i / 2);
                            if (i % 2 === 0) {
                                /* even */
                                legendIndex = 0; // e.g. Impedance
                            } else {
                                /* odd */
                                legendIndex = 1; // e.g. ESR
                            }

                            // Legend
                            let pnString = response.data.seriesList[i].kemetPn;
                            let legendString = response.data.seriesList[i].yLabel;

                            // Highlight
                            let highlightWidth = 1;
                            if (this.highlight === partListIndex) {
                                highlightWidth = 10;
                            }

                            // Order
                            let orderIndex = partListIndex + 1;
                            if (this.highlight === partListIndex) {
                                orderIndex = 0;
                            }

                            // Each `chart.js` datasets contains:
                            let dataset = {
                                pointStyle: pointStyles[partListIndex].point,
                                pointRadius: pointStyles[partListIndex].radius,
                                pointBorderWidth: pointStyles[partListIndex].width,
                                backgroundColor: pointStyles[partListIndex].color[legendIndex],
                                borderColor: pointStyles[partListIndex].color[legendIndex],
                                rotation: pointStyles[partListIndex].rotation,
                                borderWidth: highlightWidth,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString + " - " + legendString,
                                showLine: true,
                                order: orderIndex,
                            };
                            this.plotData.datasets.push(dataset);
                        }

                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "logarithmic";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: false,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                // ReSharper disable once TsNotResolved
                                const remain =
                                    value / Math.pow(10, Math.floor(Chart.helpers.log10(value)));

                                if (remain === 1 || index === 0 || index === arr.length - 1) {
                                    // order of magnitude function:
                                    // https://gist.github.com/ajmas/4193ac6911f5445ced37
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    const sign = value < 0 ? -1 : 1;
                                    value = Math.abs(value);
                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value * sign;

                                    //When zoomed, only show 2 decimal places for axis ticks at
                                    //left and right edge of graph
                                    const decimal = value - Math.floor(value);
                                    if (decimal !== 0) {
                                        value = +value.toFixed(2);
                                    }

                                    return value + " " + unitExponentsString[idx] + "Hz";
                                } else {
                                    return "";
                                }
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axis
                        this.plotOptions.scales.yAxes[0].type = "logarithmic";
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                // ReSharper disable once TsNotResolved
                                const remain =
                                    value / Math.pow(10, Math.floor(Chart.helpers.log10(value)));

                                if (remain === 1 || index === 0 || index === arr.length - 1) {
                                    // order of magnitude function:
                                    // https://gist.github.com/ajmas/4193ac6911f5445ced37
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    const sign = value < 0 ? -1 : 1;
                                    value = Math.abs(value);
                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value * sign;

                                    //When zoomed, only show 2 decimal places for axis ticks at
                                    //left and right edge of graph
                                    const decimal = value - Math.floor(value);
                                    if (decimal !== 0) {
                                        value = +value.toFixed(2);
                                    }
                                    return (
                                        value +
                                        " " +
                                        unitExponentsString[idx] +
                                        response.data.seriesList[0].yUnit
                                    );
                                } else {
                                    return "";
                                }
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false, //show the tooltip even if not directly on a point
                            mode: "interpolate", //must be interpolate for crosshair plugin
                            position: "fixedTopRight",
                            caretSize: 0,
                            callbacks: {
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now, in case needed later.
                                title(
                                    tooltipItem: Array<Chart.ChartTooltipItem>,
                                    data: Chart.ChartData
                                ) {
                                    let title = response.data.xLabel + ": ";

                                    let value = tooltipItem[0].xLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value.toFixed(2);

                                    title +=
                                        value +
                                        " " +
                                        unitExponentsString[idx] +
                                        response.data.xUnit;
                                    return title;
                                },
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now.
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";

                                    let value = tooltipItem.yLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    label += ": " + value.toFixed(1);
                                    label +=
                                        " " +
                                        unitExponentsString[idx] +
                                        response.data.seriesList[0].yUnit;
                                    return label;
                                },
                            },
                        };
                    } else if (plotType === "TCC") {
                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "linear";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: true,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                return value + " " + response.data.xUnit;
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axes
                        this.plotOptions.scales.yAxes[0].display = true;
                        this.plotOptions.scales.yAxes[0].type = "linear";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                // ReSharper disable once TsNotResolved

                                const sign = value < 0 ? -1 : 1;
                                value = Math.abs(value);
                                value = value * sign;

                                const decimal = value - Math.floor(value);
                                if (decimal !== 0) {
                                    value = +value.toFixed(2);
                                }
                                return value + response.data.seriesList[0].yUnit;
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false, //show the tooltip even if not directly on a point
                            mode: "interpolate", //must be interpolate for crosshair plugin
                            position: 'fixedTopRight',
                            callbacks: {
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now, in case needed later.
                                title(
                                    tooltipItem: Array<Chart.ChartTooltipItem>,
                                    data: Chart.ChartData
                                ) {
                                    let title = "Temp Cap Change: ";

                                    let value = tooltipItem[0].xLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];

                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value.toFixed(2);

                                    title += value + " " + unitExponentsString[idx] + "°C";
                                    return title;
                                },
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now.
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    if (
                                        // @ts-ignore
                                        isNaN(data.datasets![tooltipItem.datasetIndex!].interpolatedValue)
                                    ) {
                                        // don't show NaNs in tooltip
                                        return "";
                                    }

                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";
                                    let part: CapacitorModel = {} as CapacitorModel;
                                    let parts = response.data.request.parts;
                                    for (let i = 0; i < parts.length; i++) {
                                        if (label === parts[i].kemetPn) {
                                            part = parts[i];
                                        }
                                    }

                                    let value = tooltipItem.yLabel!;
                                    value = +value;

                                    let adjustedCapacitance = part.capValue * (1 + value / 100); // value is already negative
                                    adjustedCapacitance /= 1000000000000.0; // value is in pF, bring back to normal
                                    const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6, 9];
                                    const unitExponentsString = [
                                        "p",
                                        "n",
                                        "u",
                                        "m",
                                        "",
                                        "k",
                                        "M",
                                        "G",
                                    ];

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (
                                            adjustedCapacitance >=
                                            Math.pow(10, unitExponentsNumber[i])
                                        ) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    adjustedCapacitance =
                                        adjustedCapacitance /
                                        Math.pow(10, unitExponentsNumber[idx]);

                                    //When zoomed, only show 2 decimal places for axis ticks at
                                    //left and right edge of graph
                                    /*value = value * 100;*/
                                    label +=
                                        ": " +
                                        value.toFixed(3) +
                                        response.data.seriesList[0].yUnit +
                                        ", " +
                                        adjustedCapacitance.toFixed(1) +
                                        " " +
                                        unitExponentsString[idx] +
                                        "F";

                                    return label;
                                },
                            },
                        };

                        // Datasets
                        for (let i = 1; i < response.data.seriesList.length; i++) {
                            let partListIndex = i; // combined data style is at reserved index 0. we don't use combbined in this plot
                            let partStyleIndex = this.partStyleApplied[partListIndex];

                            let legendIndex = 0;

                            // Legend
                            let pnString = response.data.seriesList[i].kemetPn;
                            let legendString = "";

                            // Highlight
                            let highlightWidth = 1;
                            if (this.highlight === partListIndex) {
                                highlightWidth = 10;
                            }

                            // Order
                            let orderIndex = partListIndex + 1;
                            if (this.highlight === partListIndex) {
                                orderIndex = 0;
                            }

                            // Each `chart.js` datasets contains:
                            let dataset = {
                                yAxisID: legendString,
                                pointStyle: pointStyles[partStyleIndex].point,
                                pointRadius: pointStyles[partStyleIndex].radius,
                                pointBorderWidth: pointStyles[partStyleIndex].width,
                                backgroundColor: pointStyles[partStyleIndex].color[legendIndex],
                                borderColor: pointStyles[partStyleIndex].color[legendIndex],
                                rotation: pointStyles[partStyleIndex].rotation,
                                borderWidth: highlightWidth,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString,
                                showLine: true,
                                order: orderIndex,
                            };
                            this.plotData.datasets.push(dataset);
                        }
                    } else if (plotType === "VCAC") {
                        // X-Axis
                        this.plotOptions.scales.xAxes[0].type = "linear";
                        this.plotOptions.scales.xAxes[0].ticks = {
                            autoSkip: true,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                return value + " " + response.data.xUnit;
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Y-Axes
                        this.plotOptions.scales.yAxes[0].display = true;
                        this.plotOptions.scales.yAxes[0].type = "linear";
                        this.plotOptions.scales.yAxes[0].id =
                            response.data.seriesList[0].yLabel;
                        this.plotOptions.scales.yAxes[0].ticks = {
                            autoSkip: false,
                            // This callback preserves the autoSkip function of a logarithmic axis
                            callback: (value: number, index: number, arr: Array<number>) => {
                                // ReSharper disable once TsNotResolved
                                const sign = value < 0 ? -1 : 1;
                                value = Math.abs(value);
                                value = value * sign;

                                const decimal = value - Math.floor(value);
                                if (decimal !== 0) {
                                    value = +value.toFixed(2);
                                }
                                return value + response.data.seriesList[0].yUnit;
                            },
                            minRotation: this.tickRotation,
                            maxRotation: this.tickRotation,
                        };

                        // Tooltips
                        this.plotOptions.tooltips = {
                            intersect: false, //show the tooltip even if not directly on a point
                            mode: "interpolate", //must be interpolate for crosshair plugin
                            position: 'fixedTopRight',
                            callbacks: {
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now, in case needed later.
                                title(
                                    tooltipItem: Array<Chart.ChartTooltipItem>,
                                    data: Chart.ChartData
                                ) {
                                    let title = "VCAC Cap Change: ";

                                    let value = tooltipItem[0].xLabel!;
                                    const unitExponentsNumber = [0, 3, 6, 9];
                                    const unitExponentsString = ["", "k", "M", "G"];
                                    value = +value;

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    value = value / Math.pow(10, unitExponentsNumber[idx]);
                                    value = value.toFixed(2);

                                    title += value + " " + unitExponentsString[idx] + "V";
                                    return title;
                                },
                                // ReSharper disable once UnusedParameter
                                // Keep unused parameter for now.
                                label(
                                    tooltipItem: Chart.ChartTooltipItem,
                                    data: Chart.ChartData
                                ) {
                                    if (
                                        //@ts-ignore
                                        isNaN(data.datasets![tooltipItem.datasetIndex!].interpolatedValue)
                                    ) {
                                        // don't show NaNs in tooltip
                                        return "";
                                    }

                                    let label =
                                        data.datasets![tooltipItem.datasetIndex!].label || "";
                                    let part: CapacitorModel = {} as CapacitorModel;
                                    let parts = response.data.request.parts;
                                    for (let i = 0; i < parts.length; i++) {
                                        if (label === parts[i].kemetPn) {
                                            part = parts[i];
                                        }
                                    }

                                    let percent = tooltipItem.yLabel!;
                                    percent = +percent;

                                    let adjustedCapacitance = part.capValue * (percent); // percent is already negative
                                    adjustedCapacitance /= 1000000000000.0; // value is in pF, bring back to normal
                                    const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6, 9];
                                    const unitExponentsString = [
                                        "p",
                                        "n",
                                        "u",
                                        "m",
                                        "",
                                        "k",
                                        "M",
                                        "G",
                                    ];

                                    let idx = 0;
                                    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                        if (
                                            adjustedCapacitance >=
                                            Math.pow(10, unitExponentsNumber[i])
                                        ) {
                                            idx = i;
                                            break;
                                        }
                                    }
                                    adjustedCapacitance =
                                        adjustedCapacitance /
                                        Math.pow(10, unitExponentsNumber[idx]);

                                    //When zoomed, only show 2 decimal places for axis ticks at
                                    //left and right edge of graph

                                    label +=
                                        ": " +
                                        percent.toFixed(2) +
                                        response.data.seriesList[0].yUnit +
                                        ", " +
                                        adjustedCapacitance.toFixed(2) +
                                        " " +
                                        unitExponentsString[idx] +
                                        "F";

                                    return label;
                                },
                            },
                        };

                        // Datasets
                        for (let i = 1; i < response.data.seriesList.length; i++) {
                            let partListIndex = i;
                            let partStyleIndex = this.partStyleApplied[partListIndex];
                            let legendIndex = 0;

                            // Legend
                            let pnString = response.data.seriesList[i].kemetPn;
                            let legendString = "";

                            // Highlight
                            let highlightWidth = 1;
                            if (this.highlight === partListIndex) {
                                highlightWidth = 10;
                            }

                            // Order
                            let orderIndex = partListIndex + 1;
                            if (this.highlight === partListIndex) {
                                orderIndex = 0;
                            }

                            // Each `chart.js` datasets contains:
                            let dataset = {
                                yAxisID: legendString,
                                pointStyle: pointStyles[partStyleIndex].point,
                                pointRadius: pointStyles[partStyleIndex].radius,
                                pointBorderWidth: pointStyles[partStyleIndex].width,
                                backgroundColor: pointStyles[partStyleIndex].color[legendIndex],
                                borderColor: pointStyles[partStyleIndex].color[legendIndex],
                                rotation: pointStyles[partStyleIndex].rotation,
                                borderWidth: highlightWidth,
                                data: response.data.seriesList[i].points,
                                fill: false,
                                interpolate: true,
                                label: pnString,
                                showLine: true,
                                order: orderIndex,
                            };
                            this.plotData.datasets.push(dataset);
                        }
                    } else {
                        console.log("No case for this plotType.");
                    }
                })
                .finally(() => {
                    this.pendingRequests--;
                });
        },
    },
    watch: {
        // All these watchers for updating plot
        "parts.length": {
            handler: function (num /* = parts.length */) {
                this.debouncedFetchPlotData();

                // If no parts
                if (this.parts.length === 1) {
                    this.validResults = false;
                    this.pendingRequests = 0;
                }
                if (this.parts.length > 0 && this.parts[this.highlight].series.substring(0, 3) === "C44" && (this.plotType.select === "MultFreq")) {
                    this.plotType.select = "I,V"
                }
                this.debouncedFetchPlotData();
            },
        },
        "plotType.select": {
            handler: function () {
                this.debouncedFetchPlotData();
            }
        },
        individualParameters: {
            handler: function () {
                this.debouncedFetchPlotData();
            }
        },
        // TODO - on highlight change: re-build dataset without axios call
        highlight: {
            handler: function () {
                this.debouncedFetchPlotData();
            }
        },
        shared: {
            deep: true,
            immediate: false,
            handler: function () {
                this.debouncedFetchPlotData();
            }
        }
    }
});
</script>

<style>
.graph-error-msg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cap-title {
    font-size: 1.15rem;
    line-height: 3rem;
    margin-left: 0.5rem;
}

.cap-plot_outer {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: calc(100vw - 24.5rem);
    min-height: 500px;
}

.is-closed .cap-plot_outer {
    width: calc(100vw - 24.5rem + 360px);
}

.reset-zoom {
    position: relative;
    top: -3rem;
    left: 1rem;
}
</style>
