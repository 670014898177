<template>
    <div>
        <div class="seed-form">
            <div class="title">
                <h4>Seed Parameters</h4>
            </div>
            <div class="form-section">
                <h4>Simulation Parameters</h4>
            </div>
            <template v-for="(prop, index) in parts[highlight].seedProps">
                <template v-if="(prop.simulation === true) && (prop.inputType === 'number') && (prop.value != null) && (prop.name !== 'RC_Ladder_Steps')">
                    <Component :is="'base-input-'+prop.inputType"
                               class="seed-input"
                               :width=19
                               :title="prop.title"
                               :min="prop.min"
                               :max="prop.max"
                               :step="prop.step"
                               :value="prop.value"
                               v-model="prop.value"
                               :name="prop.name"
                               :isRequired="prop.required"
                               @change="(value) => onChange(prop.title, value)" />
                </template>
            </template>
            <template v-for="(prop, index) in parts[highlight].seedProps">
                <template v-if="(prop.simulation === true) && (prop.inputType === 'string') && (prop.value != null) && (prop.name != 'Step_R_Mults')">
                    <Component :is="'base-input-'+prop.inputType"
                               :width=29
                               class="seed-input"
                               :title="prop.title"
                               :min="prop.min"
                               :max="prop.max"
                               :step="prop.step"
                               v-model="prop.value"
                               :value="prop.value"
                               :name="prop.name"
                               :isRequired="prop.required"
                               @change="(value) => onChange(prop.title, value)" />
                </template>
                <template v-if="(prop.name === 'Step_R_Mults')">
                    <Component :is="'base-input-string-rc'"
                               :width=29
                               class="seed-input"
                               :title="prop.title"
                               :min="prop.min"
                               :max="prop.max"
                               :step="prop.step"
                               v-model="prop.value"
                               :value="prop.value"
                               :name="prop.name"
                               :isRequired="prop.required"
                               @change="(value) => onChange(prop.title, value)" />
                </template>
            </template>
            <div class="form-section">
                <h4>Non-Simulation Parameters</h4>
            </div>
            <div class="form-section">
                <h4 class="form-sub-category">Numbered Inputs</h4>
            </div>
            <template v-for="(prop, index) in parts[highlight].seedProps">
                <template v-if="(prop.simulation === false) && (prop.inputType === 'number') && (prop.value != null)">
                    <Component :is="'base-input-'+prop.inputType"
                               :width=29
                               class="seed-input"
                               :title="prop.title"
                               :min="prop.min"
                               :max="prop.max"
                               :step="prop.step"
                               :value="prop.value"
                               v-model="prop.value"
                               :name="prop.name"
                               :isRequired="prop.required"
                               @change="(value) => onChange(prop.title, value)" />
                </template>

            </template>
            <div class="form-section">
                <h4 class="form-sub-category">Text Inputs</h4>
            </div>
            <template v-for="(prop, index) in parts[highlight].seedProps">
                <template v-if="(prop.simulation === false) && (prop.inputType === 'string') && (prop.value != null)">
                    <Component :is="'base-input-'+prop.inputType"
                               :width=31
                               class="seed-input"
                               :title="prop.title"
                               :min="prop.min"
                               :max="prop.max"
                               :value="prop.value"
                               v-model="prop.value"
                               :name="prop.name"
                               :isRequired="prop.required"
                               @change="(value) => onChange(prop.title, value)" />
                </template>
                <template v-if="(prop.simulation === false) && (prop.inputType === 'dropdown') && (prop.value != null) && (prop.title !== 'Capacitor family')">
                    <SeedDropdown class="seed-input"
                                  :title="prop.title"
                                  v-model="prop.value"
                                  :options="prop.inputs.split(',')"
                                  :name="prop.name"
                                  @change="(value) => onChange(prop.title, value)" />
                </template>
                <template v-if="(prop.title === 'Capacitor family')">
                    <SeedDropdown class="seed-input"
                                  :title="prop.title"
                                  v-model="prop.value"
                                  :options="prop.inputs.split(',')"
                                  :name="prop.name"
                                  :disabled="true"
                                  @change="(value) => onChange(prop.title, value)" />
                </template>
            </template>
            <div class="form-break"></div>
            <div class="update-status">
                <label class="e-label">Update Status</label>
                <p v-if="previousUpdateStatus"><i>Previous Message: {{previousUpdateStatus}}</i></p>
                <textarea @change="updateStatusMessage" v-model="currentUpdateStatus" class="e-textarea"></textarea>
            </div>
            <div class="seed-form-note"
                 v-if="this.parts[this.highlight].seedProps.find(p => p.name === 'Last_Update').value !== null">
                <h5>Part last updated on {{this.parts[this.highlight].seedProps.find(p => p.name === "Last_Update").value.split("T")[0]}} by {{previousEditor || "N/A"}}</h5>
                <p>* Required field</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    //State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState } = createNamespacedHelpers('seed');

    import SeedDropdown from '@/components/seed/SeedDropdown.vue';

    export default Vue.extend({
        props: {
            name: String,
            previousUpdateStatus: String,
            previousEditor: String,
        },
        components: {
            SeedDropdown,
        },
        data() {
            return {
                currentUpdateStatus: "", 
            }
        },
        computed: {
            ...mapState([
                'highlight',
                'parts',
            ]),
        },
        methods: {
            onChange(title, output) {
                this.$emit("change", title, output);
            }, 
            updateStatusMessage() {
                this.onChange("Status as of last update", `${this.name}: ${this.currentUpdateStatus}`);
            }
        }
    })
</script>

<style>
    .form-section {
        width: 100vw;
        padding: 10px 10px;
        color: #3081c0;
    }

    .title {
        width: 100vw;
        background-color: #3081c0;
        padding: 0 8px;
        color: white;
    }

    .seed-input {
        margin: 10px 20px;
    }

    .seed-form {
        margin: 30px 0px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
        border-collapse: collapse;
        border: 1px solid var(--color-gray-lighter);
    }

    .form-sub-category {
        font-weight: 400;
    }

    .seed-form-note {
        display: flex;
        flex-wrap: wrap;
        width: 100vw;
        background-color: #3081c0;
        color: white;
        padding: 10px 30px;
        margin-top: 20px;
    }

        .seed-form-note p {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            font-style: italic;
            margin: 0px;
        }

        .seed-form-note h5 {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            font-size: 16px;
            font-weight: 200;
        }

        .form-break {
            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;
        }

        .update-status {
            width: 100%;
            margin: 20px;
        }

</style>
