// External
// Internal
// Vuex
import {ActionContext} from "vuex";
import {addCapacitor, removeCapacitor} from "../util/PartsMutations";
import {logPartUrl} from "../../util/Urls";
import initialSeedState from "../util/InitialSeedState";
import {instance} from "@/util/Alerts";

type SeedContext = ActionContext<SeedState, RootState>;

// Specific to tool
const maxParts = 2;

const state = (): SeedState => (initialSeedState);

const getters = {
    body: (state: SeedState) => {
        const freqList = [] as number[];
        for (let i = 0; i < state.shared.inputs.freqList.length; i++) {
            freqList.push(state.shared.inputs.freqList[i].select);
        }
        return {
            calculated: state.shared.radios.calculated.select,
            combine: state.shared.radios.combine.select,
            freqList: freqList,
            highlight: state.highlight,
            ind: state.shared.inputs.ind.select,
            line: state.shared.radios.line.select,
            path: state.shared.radios.path.select,
            parts: state.parts,
            plotType: "Imp,ESR",
            res: state.shared.inputs.res.select,
            spiceFileFormat: state.shared.radios.spiceFileFormat.select,
            spiceFreq: state.shared.inputs.spiceFreq.select,
            start: "10000",
            stop: state.shared.dropdowns.maxFreq.select,
            tempRise: state.shared.inputs.tempRise.select
        } as PlotRequestModel;
    },
};

const mutations = {
    addCapacitor,
    incrementComponentKey(state: SeedState) {
        state.componentKey++;
    },
    removeCapacitor,
    saveSeedProp(state: SeedState, seedProps: SeedProp[]) {
        state.parts[state.highlight].seedProps = seedProps;
    },
    saveSimulationData(state: SeedState, payload: any) {
        // ReSharper disable once TsResolvedFromInaccessibleModule
        const prop = state.parts[state.highlight].seedProps.find(p => p.title === payload.title);
        if (prop) {
            prop.value = payload.value;
        }
    },
    setDisplayDeleteModal(state: SeedState, payload: boolean) {
        state.displayDeleteModal = payload;
    },
    setDisplayForm(state: SeedState, payload: boolean) {
        state.displayForm = payload;
    },
    setHighlight(state: SeedState, partIndex: number) {
        state.highlight = partIndex;
    },
    setPartsList(state: SeedState, payload: CapacitorModel[]) {
        state.parts = payload;
    },
    setResetSeedPage(state: SeedState, payload: boolean) {
        state.resetSeedPage = payload;
        if (payload == true) {
            state.parts[state.highlight].seedProps = [];
        }
    },
    setShared(state: SeedState, payload: CalcZParameters) {
        state.shared = payload;
    },
};

const actions = {
    // TODO don't duplicate calcz.ts and seed.ts functions
    // TODO addCapacitorViaList
    addCapacitor(context: SeedContext, partToAdd: CapacitorModel) {
        if (context.state.parts.length < maxParts) {
            // Check if part exists _before_ adding part
            // TODO function partExists(context, partToAdd)
            let partExists = false;
            if (context.state.parts != null) {
                for (let i = 0; i < context.state.parts.length; i++) {
                    if (context.state.parts[i].basePn === partToAdd.basePn) {
                        partExists = true; // If match, we won't log or load CE props 
                    }
                }
            }

            // special case: duplicate added through text input 
            if (partExists) partToAdd.instances = 0;

            context.commit("addCapacitor", partToAdd);

            // log if not duplicate 
            // TODO util object for log methods
            if (!partExists) instance.post(logPartUrl, {
                partNumber: partToAdd.basePn,
                method: "fromList-seed",
                found: true,
                session: window.localStorage.getItem('uuid')
            });
        } else {
            alert(`You cannot add any more parts. The maximum is ${maxParts - 1}.`); // maxParts - combinedPart
            context.commit("incrementComponentKey"); // re-render CapacitorResults so box isn't checked
        }
    },
    //TODO addCapacitorViaPartNumber
    addCapacitorByPartNumber(context: SeedContext, payload: { partNumber: string, method: string }) {
        /* This function duplicated from CapacitorInputController because we need to be able to get
         * the basePn of the part which the user entered in order to compare
         * it to the parts already existing in the table.
         */
        function basePn(pn: string) {
            pn = pn.toUpperCase();
            if ( // Tantalum, Polymer
                (pn.substring(0, 1) === "T" || pn.substring(0, 1) === "A") &&
                ((pn.substring(0, 3) !== "TSP") && (pn.substring(0, 3) !== "A75") && (pn.substring(0, 3) !== "A76"))
            ) {
                if (pn.length > 14) {
                    if (pn.substring(14, 15) === "E")
                        pn = pn.substring(0, 18);
                    else
                        pn = pn.substring(0, 14);
                }
                pn = pn.substring(0, 8) + "M" + pn.substring(9, pn.length);
                pn = pn.substring(0, 13) + "T" + pn.substring(14, pn.length);
            }

            if ( // Ceramic
                pn.substring(0, 1) === "C" || pn.substring(0, 1) === "0"
            ) {
                // TODO: this function incorrectly does not check the series of the part number, that's why this also fails on legacy
                if (pn.length === 18 && pn.substring(14, 18) === "AUTO") {
                    // do nothing
                } else {
                    pn = pn.substring(0, 14);
                }
                pn = pn.substring(0, 9) + "K" + pn.substring(10, pn.length);
                pn = pn.substring(0, 13) + "C" + pn.substring(14, pn.length);
            }

            return pn;
        };

        // Check if list is not full
        if (context.state.parts.length < maxParts) {
            const url = `/pn/?pn=${payload.partNumber}`;
            instance.post(url, payload)
                .then((response) => {
                    if (response.data === "No match found.") {
                        alert(response.data);
                    } else {
                        context.commit("addCapacitor", response.data);
                        //@ts-ignore
                        this.dispatch("seed/loadSeedProp");
                    }
                });

            let partExists = false; // Assume part not in list to begin
            // Check if part is in list already
            for (let i = 0; i < context.state.parts.length; i++) {
                if (context.state.parts[i].basePn === basePn(payload.partNumber)) { // Identify based on part number
                    partExists = true; // If already in list: don't log 
                }
            }
        }
        // Parts list is full
        else {
            alert(`You cannot add any more parts. The maximum is ${maxParts - 1}.`);
        }
    },
    incrementComponentKey(context: SeedContext) {
        context.commit("incrementComponentKey");
    },
    loadSeedProp(context: SeedContext) {
        return instance.post("/api/seed/pn",
            {
                id: context.state.parts[context.state.highlight].id
            }).then((response) => {
            context.commit("saveSeedProp", response.data);
            context.commit("setDisplayForm", true);
        });
    },
    removeCapacitorByPn(context: SeedContext, basePn: string) {
        // TODO if locked don't remove
        // Go backwards - we want to delete root part last
        for (let i = context.state.parts.length - 1; i >= 0; i--) {
            if (context.state.parts[i].basePn === basePn) {
                context.commit("removeCapacitor", i);
                break;
            }
        }
    },
    setDisplayDeleteModal(context: SeedContext, payload: boolean) {
        context.commit("setDisplayDeleteModal", payload);
    },
    setDisplayForm(context: SeedContext, payload: boolean) {
        context.commit("setDisplayForm", payload);
    },
    setHighlight(context: SeedContext, payload: number) {
        context.commit("setHighlight", payload);
    },
    setPartsList(context: SeedContext, payload: CapacitorModel[]) {
        context.commit("setPartsList", payload);
    },
    setResetSeedPage(context: SeedContext, payload: boolean) {
        context.commit("setResetSeedPage", payload);
    },
    setShared(context: SeedContext, payload: CalcZParameters) {
        // TODO: always in combined mode by default? or combined simply overwrites bias and tempRise?
        if (payload.radios.combine.select === "Yes") {
            context.commit("setHighlight", 0); // Combined is reserved index 0 in CalcZ
        } else { // If user changed: combine.select = 'No'
            if (context.getters.highlight === 0) { // ...and highlight still 'Combined'...
                context.commit("setHighlight", 1); // Highlight first part. Combined is not even shown.
            }
        }
        // Finally, update the shared parameters
        context.commit("setShared", payload);
    },
    updateSimulationData(context: SeedContext, payload: object) {
        //@ts-ignore
        // ReSharper disable TsResolvedFromInaccessibleModule
        context.commit("saveSimulationData", {title: payload.title, value: payload.output});
        // ReSharper restore TsResolvedFromInaccessibleModule
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
