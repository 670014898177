<template>

  <div >
    <div v-if="powerArrayResults.length > 0" class="pac_table">
      <table class="" id="table-container" style="margin-top: 1rem">
        <thead class="pac_thead">
        <tr class="cap-tr" id="table-row">
          <th class="pac-th" rowspan="2" @click="sortByString(powerArrayResults,'partNumber')">Part Number
            <sort-arrow style="cursor: pointer"/>
          </th>
          <th class="pac-th" rowspan="2" @click="sortByString(powerArrayResults,'seriesParallelCombination')">Series/
            &nbsp&nbsp&nbsp&nbsp
            <br/>Parallel
            <sort-arrow style="cursor: pointer; "/>
          </th>
          <th class="pac-th_stk" rowspan="2" style="word-wrap: break-word;" @click="sortByString(powerArrayResults,'totalMlccs')">Total
            Ceramic
            &nbsp&nbsp&nbsp
            <br>Capacitors
            <sort-arrow style="cursor: pointer; "/>
          </th>
          <th class="pac-th" rowspan="2" @click="sortByString(powerArrayResults,'arrayCapacitance')">Array
            &nbsp&nbsp&nbsp&nbsp&nbsp
            <br/>Capacitance
            <sort-arrow style="cursor: pointer; "/>
          </th>
          <th class="pac-th_stk" colspan="2" @click="sortByString(powerArrayResults,'totalPrice')">Relative Normalised Price
          </th>

          <th class="pac-th" rowspan="2" @click="sortByString(powerArrayResults,'arrayVoltageRating')">&nbsp&nbspArray&nbsp&nbspRated
            &nbsp&nbsp
            <br>Voltage : Margin
            <br/>&nbsp&nbsp&nbsp&nbsp&nbspV&nbsp&nbsp&nbsp : &nbsp&nbsp
            %
            <sort-arrow style="cursor: pointer; "/>
          </th>
          <th class="pac-th" rowspan="2" @click="sortByString(powerArrayResults,'arrayCurrentRating')">&nbsp&nbspArray&nbsp&nbspRated
            &nbsp&nbsp
            <br/>Current : Margin
            <br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbspA&nbsp&nbsp : &nbsp&nbsp%
            <sort-arrow style="cursor: pointer; "/>
          </th>
          <th class="pac-th"  rowspan="2" @click="sortByString(powerArrayResults,'chipSize')">Case
            &nbsp&nbsp
            <br/>Size
            <sort-arrow style="cursor: pointer;"/>
          </th>
          <th class="pac-th"  rowspan="2" @click="sortByString(powerArrayResults,'footPrint')">Footprint
            <br/>m㎡
            <sort-arrow style="cursor: pointer"/>
          </th>
          <th class="pac-th" rowspan="2" title="Expand (click the arrow) to view more information.">Detail</th>
        </tr>
<!--      second row -->
        <tr>
<!--          <th class="pac-th"  @click="sortByString(powerArrayResults,'seriesParallelCombination')">Parallel-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'totalMlccs')">Capacitors-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'arrayCapacitance')">Capacitance-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
          <th class="pac-th" style="font-size: 0.9rem" @click="sortByString(powerArrayResults,'totalPrice')">Total
            <sort-arrow style="cursor: pointer"/>
          </th>
          <th class="pac-th"style="font-size: 0.9rem"  @click="sortByString(powerArrayResults,'singlePrice')">Single
            <sort-arrow style="cursor: pointer"/>
          </th>
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'arrayVoltageRating')">Voltage : Margin-->
<!--            <br/>&nbsp&nbsp&nbsp&nbsp&nbspV&nbsp&nbsp&nbsp : &nbsp&nbsp%-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'arrayCurrentRating')">Current : Margin-->
<!--            <br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbspA&nbsp&nbsp : &nbsp&nbsp%-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'chipSize')">Size-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->
<!--          <th class="pac-th" @click="sortByString(powerArrayResults,'footPrint')">m㎡-->
<!--            <sort-arrow style="cursor: pointer"/>-->
<!--          </th>-->

        </tr>
        </thead>
        <tbody class="ind-tbody">
        <template v-for="(part, index) in stockFilters.slice(currPage*rowsInPage-rowsInPage,rowsInPage*currPage)">

          <tr :key="'indPart'+index"
              :class="{ 'is-open' : infoOpen.includes(index)}"
              class="ind-tr">

            <td class="ind-td" >
              <div style="text-align: left;padding-left: 10%; margin-right: 2rem" >
              <a target="_blank" :href="partDetailSearchUrl + part.partNumber"
                 >
                <strong >{{ replacePartNumber(part.partNumber) }}</strong>
              </a>
              </div>
            </td>

            <td class="ind-td">

              <strong>{{ part.seriesParallelCombination }}</strong>
            </td>

            <td class="ind-td">

              <strong>{{ part.totalMlccs }}</strong>
            </td>
            <td class="ind-td">

              <strong>{{ convertToInteger(part.arrayCapacitance) }}</strong>
            </td>
            <td class="ind-td">
              <strong v-if="part.totalPrice===0">N/A</strong>
              <strong v-else
                      style="cursor: pointer; color: blue"
                      @click="showInfo(index);"
              >
                {{ roundTo(part.totalPrice / lowestTotalPrice, 2) }}
              </strong>
            </td>

            <td class="ind-td">
              <strong v-if="part.singlePrice===0">N/A</strong>
              <strong v-else
                      style="cursor: pointer; color: blue"
                      @click="showInfo(index);"
              >
                {{ roundTo(part.singlePrice / lowestSinglePrice, 2) }}
              </strong>
            </td>


            <td class="ind-td">
              <strong>{{ roundTo(part.arrayVoltageRating, 0) + " : " + roundTo(100-100 * acVoltage / part.arrayVoltageRating, 0) }}</strong>
            </td>
            <td class="ind-td">
              <strong>{{ roundTo(part.arrayCurrentRating, 0) + " : " + roundTo(100-100 * acCurrent / part.arrayCurrentRating, 0) }}</strong>
            </td>
            <!--                  <td class="ind-td">-->
            <!--                    <strong v-if="!part[index].totalStock">	❌</strong>-->
            <!--                    <strong v-else style="color: green">✓</strong>-->
            <!--                  </td>-->


            <td class="ind-td">
              <strong>{{ part.chipSize }}</strong>
            </td>

            <td class="ind-td">
              <strong>{{ Math.round(part.footPrint) }}</strong>
            </td>


            <td title="Tip: press 'ESC' to close all info views."
                class="ind-td"
                v-if="stockFilters.length > 0"
                :rowspan="1"
                >
              <svg class="ind-icon ind-icon_expand" style="cursor: pointer" @click="showInfo(index);">
                <use xlink:href="#arrow-down"></use>
              </svg>
            </td>

          </tr>

          <!--Expanded Information from CE-->
          <tr class="ind-tr ind-info"
              :key="'indCeInfo'+index"
          >

            <td class="ind-td" colspan=12>
              <div class="ind-info_block" :class="{ 'is-open' : infoOpen.includes(index) }">
                <div class="l-spaced:4 l-box:inset2" style="--loc-spaced-width: 20em; ">
                  <PacBasicInfoTable style=" margin-left: 4rem;margin-top: 0;"
                                     :array-capacitance=convertToInteger(part.arrayCapacitance)
                                     :series=part.series
                                     :part-number=replacePartNumber(part.partNumber)
                  />
                  <PacOptionalTable style="display: inline; width: 40%; margin-left:4rem;margin-top: 2rem"
                                    :array-capacitance=part.arrayCapacitance
                                    :array-current-rating=roundTo(part.arrayCurrentRating,0)
                                    :array-voltage-rating=roundTo(part.arrayVoltageRating,0)
                                    :mlcc-current=roundTo(part.mlccCurrent,1)
                                    :mlcc-current-rating=roundTo(part.mlccCurrentRating,1)
                                    :mlcc-voltage=roundTo(part.mlccVoltage,-1)
                                    :mlcc-voltage-rating=roundTo(part.mlccVoltageRating,0)
                  />
                  <PriceTable style="display: inline; width: 20%; margin-top: -3rem"
                              :digi-key-price-list=part.digiKeyPriceList
                              :mouser-price-list=part.mouserPriceList
                              :digi-key-stock=part.digiKeyStock
                              :mouser-stock=part.mouserStock
                  />
                </div>

              </div>
            </td>
          </tr>
        </template>

        </tbody>
      </table>
      <div class="pagination" style="margin-right: 2rem; margin-top: 1rem">
        <a style="margin-top: 1rem">{{ "Total: " + stockFilters.length + " rows" }}</a>



        <ul style="float: right">
          <a href="#" v-bind:class="{'disabled_prev': (currPage === 1)}"
             style="margin-left: 1rem"
             @click.prevent="setPage(currPage-1)">Prev</a>
          <a href="#" v-for="n in totalPage"
             style="margin-left: 1rem"
             v-bind:class="{'disabled': (currPage === (n))}"
             @click.prevent="setPage(n)">{{ n }}</a>
          <a href="#" v-bind:class="{'disabled_prev': (currPage === totalPage)}"
             style="margin-left: 1rem"
             @click.prevent="setPage(currPage+1)">Next</a>
        </ul>

        <div style="float: right; margin-right: 2rem">
          <select v-model="rowsInPage" >
            <option  selected label="20" value="20"/>
            <option label="50" value="50"/>
            <option  label="100" value="100"/>
            <option label="150" value="150"/>
            <option label="200" value="200"/>
          </select>
          <a style="margin-left: 0.5rem">rows per page</a>

        </div>
      </div>
    </div>
    <!--        If no parts-->
    <div v-else-if="powerArrayResults.length<1 && searchResult===true" class="l-place:center" style="margin-top: 10rem;">
      <div v-if="errorAlert"/>
      <strong style="font-size: 1.5rem">
        Enter * Parameters in the left sidebar boxes.
      </strong>
    </div>
    <div v-else class="l-place:center" style="margin-top: 10rem;">
      <strong style="font-size: 1.5rem">
        Enter * Parameters in the left sidebar boxes.
      </strong>
    </div>
  </div>
</template>

<script lang="ts">

import {linkMixin} from "@/mixins/linkMixin";

//Vuex state
import {createNamespacedHelpers} from 'vuex';
import Vue from 'vue';
import {instance} from "@/util/Alerts";
import PacOptionalTable from "@/components/powerArrayCalculator/PacOptionalTable.vue";
import SortArrow from "@/components/powerArrayCalculator/sortArrow.vue";
import PacPriceTable from "@/components/powerArrayCalculator/PriceTable.vue";
import PriceTable from "@/components/powerArrayCalculator/PriceTable.vue";
import Buttons from "@/components/seed/Buttons.vue";
import PacBasicInfoTable from "@/components/powerArrayCalculator/PacBasicInfoTable.vue";

const {mapState, mapActions} = createNamespacedHelpers('pac');

export default Vue.extend({
  components: {
    PriceTable,
    PacBasicInfoTable,
    Buttons,
    PacPriceTable, SortArrow, PacOptionalTable
  },
  mixins: [linkMixin],
  data() {
    return {
      infoOpen: [] as number[],
      currPage: 1,
      rowsInPage: 20,
    }
  },
  computed: {
    ...mapState([
      'powerArrayResults',
      'searchResult',
      'sortOrder',
      'lowestSinglePrice',
      'lowestTotalPrice',
      'stockFilter',
      'acVoltage',
      'acCurrent',
      'caseSize',
      'powerArrayTable',
      'searchInput',
    ]), errorAlert() {
      this.$fire({
        title: "Data Not Found.",
        text: "Please Check * Parameters",
        type: "error",
        timer: 5000
      }).then(r => {
        return true;
      })
    },
    stockFilters() {
      let searchFilted: PacResultModel[] = []
      let searchString = this.searchInput
      let acCurrent = this.acCurrent
      let acVoltage = this.acVoltage
      searchFilted = this.powerArrayResults.filter(function (ele) {
        let currentMargin = 100-100 * acCurrent / ele.arrayCurrentRating
        let voltageMargin = 100-100 * acVoltage / ele.arrayVoltageRating
        return currentMargin>=20&&voltageMargin>=20&&ele.caseSize!=""
      })


      if (this.searchInput != "") {
        this.setPage(1)
        searchFilted = searchFilted.filter(function (ele) {
          return ele.partNumber.includes(searchString)
        })
      }

      if (this.stockFilter == "yes") {
        this.setPage(1)
        let hasStock: PacResultModel[] = []
        hasStock = searchFilted.filter(function (ele) {
          return ele.totalStock === true
        })

        return hasStock
      } else if (this.stockFilter == "all") {
        this.setPage(1)
        return searchFilted
      }
    },
    totalPage() {
        return Math.ceil(this.stockFilters.length / this.rowsInPage)
    },


  },
  watch: {
    'rowsInPage':{
      handler:function (val){
        this.setPage(1)
      }
    }
  },
  methods: {
    ...mapActions([
      'setSortOrder',
      'setPacResult',
      'setPowerArrayTable',
    ]),

    // Keep track of which indexes are open
    replacePartNumber: function (partNumber: string) {
      if (partNumber.length < 10) {
        return partNumber;
      }

      if (partNumber[9] === "K") {
        return partNumber.substring(0, 9) + "J" + partNumber.substring(10);
      }

      return partNumber;
    },

    // Keep track of which indexes are open
    showInfo: function (rowIndex: any) {
      //If already in list (being shown), remove from list
      if (this.infoOpen.includes(rowIndex)) {
        const idx = this.infoOpen.findIndex(row => row === rowIndex);
        this.infoOpen.splice(idx, 1);
      }
      // Else add to list
      else {
        this.infoOpen.push(rowIndex);
      }
    },
    roundTo: function (num, decimal) {
      return Math.round((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
    },
    sortByString(data, field) {
      let order = this.sortOrder
      if (order) {
        data.sort((a, b) => {
          this.setSortOrder(!order)
          if (a[field] < b[field])
            return -1;
          if (a[field] > b[field])
            return 1;
          return 0;
        })
      } else {
        data.sort((a, b) => {
          this.setSortOrder(!order)
          if (a[field] < b[field])
            return 1;
          if (a[field] > b[field])
            return -1;
          return 0;
        });
      }

    },
    setPage(page: number) {
      this.currPage = page
    },
    convertToInteger(scientificNotation: number): number {
      const normalNumber: number = scientificNotation * 1e9;
      const roundedValue: number = Math.round(normalNumber);
      return roundedValue;
    }
  },
  mounted() {
    // Escape to close all expanded info
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.infoOpen = [];
      }
    });
    instance.get("/auth/bannerImageRequest/").then((response) => {
      (this as any).bannerImage = response.data;
    });
  },
});
</script>

<style scoped>

.pac_thead {
  //display:table;
  //width: 100%;
  position: sticky; /* 设置表头固定 */
  top: 0; /* 表头置于容器顶部 */
  z-index: 1; /* 设置z-index使表头在容器内浮动 */
  border: black solid 0.3rem;
  empty-cells: show;

}

.cap-parts {
  margin-bottom: 2rem;
}


.cap-tr {

  box-shadow: inset 0.05rem 0 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out;

  &.is-open {
    background-color: hsla(206, 60%, 47%, 0.14);
  }

}

.cloned.is-open + .cap-tr {
  background-color: hsla(206, 60%, 47%, 0.14);
}

.pac-th,
.cap-td {
  box-shadow: inset -0.1rem 0 0 black;
  width: 1%;
  white-space: nowrap;
}

.pac-th_stk {
  box-shadow: inset -0.1rem 0 0 black;
  width: 2%;
  //white-space: nowrap;
  color: #fff;
  /* background-color: var(--color-main); */
  background-color: #3081c0;
  border-color: #1c1f2b;
  //border-style: outset;
  font-size: 1rem;
  font-weight: bolder;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}

.pac-th_price {
  box-shadow: inset -0.1rem 0 0 black;
  width: 5%;
  //white-space: nowrap;
  color: #fff;
  /* background-color: var(--color-main); */
  background-color: #3081c0;
  //border-color: #1c1f2b;
  //border-style: outset;
  font-size: 0.875rem;
  font-weight: bolder;
  text-wrap: normal;
  word-wrap: break-word;
}

.pac-th {
  color: #fff;
  /* background-color: var(--color-main); */
  background-color: #3081c0;
  //border-color: #1c1f2b;
  //border-style: outset;
  font-size: 1rem;
  font-weight: bolder;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}


.cap-td .l-field {
  margin-bottom: 0;
}




.cap-info_block {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  will-change: max-height;


}

.cloned.is-open + .cap-tr + .cap-tr .cap-info_block {
  max-height: 62.5rem;
}


.is-open .cap-icon_expand {
  transform: rotate(90deg);
}

.ind-td {

  text-align: center;
  box-shadow: inset -0.063rem 0.063rem 0 rgba(0, 0, 0, 0.1);
  width: 1%;
  white-space: nowrap;
  font-weight: bold;
}

.is-open .ind-icon_expand {
  transform: rotate(180deg);
}

.disabled {
  pointer-events: none;
  color: red;
}
.disabled_prev {
  pointer-events: none;
  color: grey;
}
.ind-info_block {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  //will-change: max-height;
  &.is-open
  {
    max-height: 60rem;
  }

}

/* For Safari */
.ind-info_block.is-open {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 60rem;
}

</style>